import React, { Component } from 'react';
import { TextAlignProperty } from 'csstype';

import '../index.scss';

type Heading1Props = {
  textAlign?: TextAlignProperty,
};

class Heading1 extends Component<Heading1Props, {}> {
  static defaultProps = {
    textAlign: 'left',
  };

  render() {
    const { children, textAlign } = this.props;
    return (
      <h1 style={{ textAlign }}>{children}</h1>
    );
  }
}

export default Heading1;
