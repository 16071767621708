import React, { Component } from 'react';

import Header from '../../components/Molecules/Header';
import Combo from './Combo';

import './index.scss';

class Admin extends Component {
  render() {
    return (
      <>
        <Header text="HopTrip Admin"/>
        <section className="panel admin">
          <Combo/>
        </section>
      </>
    );
  }
}
export default Admin;
