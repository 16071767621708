import React, { Component, Dispatch, MouseEvent } from 'react';
import { State } from '../../../store/reducers';
import { addStartAction } from '../../../store/combos/actions';
import { connect } from 'react-redux';
import Heading2 from '../../../components/Atoms/Heading/Heading2';
import ComboView from '../ComboView';
import ComboList from '../ComboList';
import ComboDto from '../../../dtos/combo.dto';

type Props = {
  combo: ComboDto,
  add: (e: MouseEvent<HTMLButtonElement>) => void,
};

class Combo extends Component<Props> {
  render() {
    return (
      <>
        <div className="admin-item">
          <Heading2>Combos</Heading2>
          {
            Object.keys(this.props.combo).length === 0
              ? <button className="btn-submit" onClick={this.props.add}> Adicionar Combo</button>
              : null
          }
        </div>
        <ComboView/>
        <br/>
        <ComboList/>
      </>
    );
  }
}

const mapStateToProps = (state: State) => ({
  combo: state.comboAddEdit,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  add: (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(addStartAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Combo);
