import React, { Component, ReactNode } from 'react';

import Heading2 from '../../Atoms/Heading/Heading2';

import './index.scss';

type SectionProps = {
    title: string,
    image: string,
    children: ReactNode,
}

class Section extends Component<SectionProps, {}> {
  render() {
    const { title, image, children } = this.props;
    return (
      <div className="section-container">
        <div className="section-item section-image">
          <img alt="" src={ image }/>
        </div>

        <div className="section-item section-content">
          <div className="section-content-header">
            <Heading2>{ title }</Heading2>
          </div>
          <div className="section-content-text">
            { children }
          </div>
        </div>
      </div>
    );
  }
}

export default Section;
