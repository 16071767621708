import React, { Component } from 'react';

import './index.scss';

type ContentProps = {
  image?: string,
};

class Content extends Component<ContentProps, {}> {
  render() {
    const { image, children } = this.props;

    return (
      <div className="content">

        <div className="content-item">
          {children}
        </div>

        {
          image
            ? <div className="content-item content-image">
              <img alt="" src={image}/>
            </div>
            : null
        }
      </div>
    );
  }
}

export default Content;
