import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyB3VR_hy7lGngDaVtlTQ-tpGkPj5uPOBPI',
  authDomain: 'hoptrip-1531073608614.firebaseapp.com',
  databaseURL: 'https://hoptrip-1531073608614.firebaseio.com',
  projectId: 'hoptrip-1531073608614',
  storageBucket: 'hoptrip-1531073608614.appspot.com',
  messagingSenderId: '151261993513',
  appId: '1:151261993513:web:c56d52fdb95a22b1f51a38',
  measurementId: 'G-EBS41K3MSM',
};

firebase.initializeApp(firebaseConfig);

export const uploadFile = (child: string, file: File): Promise<string> => {
  const ref = firebase.storage().ref();

  return new Promise<string>((resolve, reject) => {
    const uploadTask = ref.child(`${child}${file.name}`);

    uploadTask.put(file)
      .then(snapshot =>
        snapshot.ref.getDownloadURL()
          .then(resolve)
          .catch(reject),
      ).catch(reject);
  });
};

export const login = (user: string, password: string) => {
  firebase.auth()
    .signInWithEmailAndPassword(user, password)
    .then(console.info)
    .catch(console.error);




};

export const getUserAuthEmail = (): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.email) {
        return resolve(user.email);
      } else {
        return reject();
      }
    });
  });
};

export const database = firebase.database();
