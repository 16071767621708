import React, { Component, ReactNode } from 'react';

import { TextAlignProperty } from 'csstype';

import '../index.scss';
import './index.scss';

type Heading2Props = {
  ico?: boolean,
  textAlign?: TextAlignProperty,
  children: ReactNode,
}

class Heading2 extends Component<Heading2Props, {}> {
  public static defaultProps = {
    ico: false,
    textAlign: 'left',
  };

  render() {
    const { ico, textAlign, children } = this.props;

    return (
      <>
        {ico
          ? (
            <span className="subtitle-icon">
              <h2 style={{ textAlign }}>{children}</h2>
            </span>
          )
          : (<h2 style={{ textAlign }}>{children}</h2>)
        }
      </>
    );
  }
}

export default Heading2;
