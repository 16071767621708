import TextDto from './text.dto';

export default interface ComboDto {
  id?: string,
  name: string;
  amount: number;
  image: string;
  imageThumb: string;
  ps?: string;
  texts: TextDto[];
}

export function getDefaultInstance(): ComboDto {
  return {
    texts: [{
      title: 'Beers',
      items: [{
        text: '',
      }],
    }, {
      title: 'Snacks',
      items: [{
        text: '',
      }],
    }],
  } as ComboDto;
}
