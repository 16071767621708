import React, { Component } from 'react';

import './index.scss';

type ParallaxProps = {
  desktopImage: any,
  mobileImage: any,
};

type ParallaxState = {
  backgroundImage: any,
};

class Parallax extends Component<ParallaxProps, ParallaxState> {
  constructor(props: ParallaxProps) {
    super(props);
    this.state = { backgroundImage : this.getBackgroundImage() };
  }

  render() {
    const { children } = this.props;
    const imgStyle = {
      backgroundImage: 'url(' + this.state.backgroundImage + ')',
    };

    return (
      <section className="parallax">
        <div className="parallax-bg" style={ imgStyle }>
          { children }
        </div>
      </section>
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  getBackgroundImage() {
    if (window.innerWidth >= 650 && window.innerHeight < 1024) {
      return this.props.desktopImage;
    } else {
      return this.props.mobileImage;
    }
  }

  resize() {
    this.setState({
      backgroundImage: this.getBackgroundImage(),
    });
  }
}

export default Parallax;
