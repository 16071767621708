import React, { Component, Dispatch, FormEvent, MouseEvent } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { State } from '../../../store/reducers';
import { userAuthCheck, userLogin } from '../../../store/combos/actions';

import { login as firebaseLogin } from '../../../utils/firebase';

import Modal from '../../../components/Atoms/Modal';
import Heading2 from '../../../components/Atoms/Heading/Heading2';

import './index.scss';

type Props = {
  isAuthenticated: boolean;
  userCheck: () => void;
  login: (email: string) => void;
}

type LocalState = {
  email: string,
  password: string,
}

class Login extends Component<Props, LocalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      password: '',
    }
  }
  componentDidMount() {
    this.props.userCheck();
  }

  onChangeHandler = (event: FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;

    if (target.name === 'email') {
      this.setState({
        email: target.value,
      });
    }

    if (target.name === 'password') {
      this.setState({
        password: target.value,
      });
    }
  };

  async login(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    const { email, password } = this.state;

    await firebaseLogin(email, password);

    // dispatch an action
    this.props.login(email);
  }

  render() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/admin',
            state: { from: '/login' },
          }}
        />
      );
    }

    const { email, password } = this.state;

    return (
      <section className="admin">
        <Modal onClose={() => null} show={true}>
          <Heading2 ico={true}> HopTrip Admin </Heading2>
          <br/>

          <form>
            <div className="col-1">
              <label>
                Nome *
                <input
                  type="email"
                  onChange={this.onChangeHandler}
                  placeholder="Qual e-mail?"
                  value={email}
                  name="email"
                />
              </label>
            </div>
            <div className="col-1">
              <label>
                Senha *
                <input
                  type="password"
                  onChange={this.onChangeHandler}
                  placeholder="Senha"
                  value={password}
                  name="password"
                />
              </label>
            </div>
            <div className="col-submit">
              <button className="btn-submit" onClick={(e) => this.login(e)}> Login</button>
            </div>
          </form>
        </Modal>

      </section>
    );
  }
}

const mapStateToProps = (state: State) => ({
  isAuthenticated: state.authEmail ? true : false,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  userCheck: () => {
    dispatch(userAuthCheck());
  },
  login: (email: string) => {
    dispatch(userLogin(email));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
