import ComboDto from '../../dtos/combo.dto';

export const ACTION_ID = {
  // LIST
  LIST_ALL_REQUEST_START:
    'Request all combos.',
  LIST_ALL_REQUEST_COMPLETED:
    'Async service returned the combos.',
  LIST_ALL_REQUEST_FAILED:
    'Failed to retrieve combos.',

  // FORM
  FORM_CANCEL:
    'Clean combo state when form has been canceled.',

  // EDIT
  EDIT_START:
    'Set a combo to be edited.',
  EDIT_REQUEST:
    'Update combo edited.',
  EDIT_COMPLETE:
    'Update combo completed.',
  EDIT_FAILED:
    'Update combo failed.',

  // SAVE
  ADD_START:
    'Create an empty combo instance to add.',
  ADD_REQUEST:
    'Perform a request to save combo.',
  ADD_COMPLETE:
    'Add combo completed.',
  ADD_FAILED:
    'Add combo failed.',

  // DELETE
  DELETE_START:
    'Delete combo starts.',
  DELETE_REQUEST:
    'Delete combo.',
  DELETE_CANCEL:
    'Delete combo canceled.',
  DELETE_COMPLETE:
    'Delete combo completed.',
  DELETE_FAILED:
    'Delete combo failed.',

  USER_AUTH_CHECK:
    'Check if the user is login.',
  USER_LOG_IN:
    'User login.',
  USER_LOG_OFF:
    'User logoff',
};

export interface ComboListAllAction {
  type: string;
  payload?: ComboDto[];
}

export interface ComboAction {
  type: string;
  payload?: ComboDto;
  err?: string;
}

export interface AuthAction {
  type: string;
  email?: string;
}

// list
export const listAllStartAction = (): ComboListAllAction => ({
  type: ACTION_ID.LIST_ALL_REQUEST_START,
});

export const listAllCompletedAction = (
  combos: ComboDto[]
): ComboListAllAction => ({
  type: ACTION_ID.LIST_ALL_REQUEST_COMPLETED,
  payload: combos,
});

export const listAllFailedAction = (err: string): ComboAction => ({
  type: ACTION_ID.ADD_FAILED,
  err,
});

// form
export const formCancelAction = (): ComboAction => ({
  type: ACTION_ID.FORM_CANCEL,
});

// add
export const addStartAction = (): ComboAction => ({
  type: ACTION_ID.ADD_START,
  payload: {} as ComboDto,
});

export const addRequestAction = (combo: ComboDto): ComboAction => ({
  type: ACTION_ID.ADD_REQUEST,
  payload: combo,
});

export const addCompleteAction = (): ComboAction => ({
  type: ACTION_ID.ADD_COMPLETE,
});

export const addFailedAction = (err: string): ComboAction => ({
  type: ACTION_ID.ADD_FAILED,
  err,
});

// edit
export const editStartAction = (combo: ComboDto): ComboAction => ({
  type: ACTION_ID.EDIT_START,
  payload: combo,
});

export const editRequestAction = (combo: ComboDto): ComboAction => ({
  type: ACTION_ID.EDIT_REQUEST,
  payload: combo,
});

export const editCompleteAction = (): ComboAction => ({
  type: ACTION_ID.EDIT_COMPLETE,
});

export const editSaveFailedAction = (err: string): ComboAction => ({
  type: ACTION_ID.EDIT_FAILED,
  err,
});

// delete
export const deleteStartAction = (combo: ComboDto): ComboAction => ({
  type: ACTION_ID.DELETE_START,
  payload: combo,
});

export const deleteRequestAction = (combo: ComboDto): ComboAction => ({
  type: ACTION_ID.DELETE_REQUEST,
  payload: combo,
});

export const deleteCancelAction = (): ComboAction => ({
  type: ACTION_ID.DELETE_CANCEL,
});

export const deleteCompleteAction = (): ComboAction => ({
  type: ACTION_ID.DELETE_COMPLETE,
});

export const deleteFailedAction = (err: string): ComboAction => ({
  type: ACTION_ID.DELETE_FAILED,
  err,
});


export const userAuthCheck = (): AuthAction => ({
  type: ACTION_ID.USER_AUTH_CHECK,
});

export const userLogin = (email: string): AuthAction => ({
  type: ACTION_ID.USER_LOG_IN,
  email,
});


