import { ACTION_ID, AuthAction, ComboAction, ComboListAllAction } from './actions';
import ComboDto, { getDefaultInstance } from '../../dtos/combo.dto';

export type ListAllState = ComboDto[];
export type ComboState = ComboDto;
export type AuthState = string;

export const listAllReducer = (
  state: ListAllState = [],
  action: ComboListAllAction,
) => {
  switch (action.type) {
    case ACTION_ID.LIST_ALL_REQUEST_COMPLETED:
      return action.payload || [];
  }
  return state;
};

export const addEditReducer = (
  state: ComboState = {} as ComboDto,
  action: ComboAction,
) => {
  switch (action.type) {
    case ACTION_ID.ADD_START:
      return getDefaultInstance();

    case ACTION_ID.ADD_REQUEST:
    case ACTION_ID.EDIT_START:
    case ACTION_ID.EDIT_REQUEST:
      if (!action.payload) throw new Error('The object to edit cannot be undefined');
      return action.payload;

    case ACTION_ID.ADD_COMPLETE:
    case ACTION_ID.ADD_FAILED:
    case ACTION_ID.EDIT_COMPLETE:
    case ACTION_ID.EDIT_FAILED:
      console.error(action.err);
      return {} as ComboDto;

    case ACTION_ID.FORM_CANCEL:
      return {} as ComboDto;
  }
  return state;
};

export const deleteReducer = (
  state: ComboState = {} as ComboDto,
  action: ComboAction,
) => {
  switch (action.type) {
    case ACTION_ID.DELETE_START:
    case ACTION_ID.DELETE_REQUEST:
      if (!action.payload) throw new Error('The object to edit cannot be undefined');
      return action.payload;

    case ACTION_ID.DELETE_CANCEL:
      return {} as ComboDto;

    case ACTION_ID.DELETE_COMPLETE:
    case ACTION_ID.DELETE_FAILED:
      console.error(action.err);
      return {} as ComboDto;
  }
  return state;
};

export const authReducer = (
  state: AuthState = '',
  action: AuthAction,
) => {
  switch (action.type) {
    case ACTION_ID.USER_LOG_IN:
    case ACTION_ID.USER_LOG_OFF:
      return action.email || '';
  }
  return state;
};
