import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';

import { listAllStartAction, editStartAction } from '../../../store/combos/actions';

import { State } from '../../../store/reducers';
import ComboDto from '../../../dtos/combo.dto';

import './index.scss';

type Props = {
  requestCombos: () => void,
  editCombo: (combo: ComboDto) => void,
  combos: ComboDto[];
}

class ComboList extends Component<Props> {
  componentDidMount() {
    this.props.requestCombos();
  }

  render() {
    return (
      <section className="combo-list">
        <table>
          <tbody>
          <tr>
            <th>Nome</th>
            <th>Valor</th>
          </tr>
          {this.props.combos.map(combo => (
            <tr key={combo.id} onClick={() => this.props.editCombo(combo)}>
              <td style={{ width: '50%' }}>{combo.name}</td>
              <td style={{ textAlign: 'right', width: '50%' }}>{combo.amount}€</td>
            </tr>
          ))}
          </tbody>
        </table>
      </section>
    );
  }
}

const mapStateToProps = (state: State) => ({
  combos: state.comboList,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  requestCombos: () => dispatch(listAllStartAction()),
  editCombo: (combo: ComboDto) => dispatch(editStartAction(combo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComboList);
