import React, { Component } from 'react';

import Heading2 from '../../Atoms/Heading/Heading2';
import Content from '../../Molecules/Content';

import AboutImg from '../../../assets/images/about/about.png';

import './index.scss';

class About extends Component {
  render() {
    return (
      <section className="panel">
        <Heading2>Sobre Nós</Heading2>

        <Content image={AboutImg}>
          <p>
            Devido ao nosso grande amor por cerveja, decidimos abrir a <strong>HopTrip – Craft Beer
            Shop</strong>. Hop, em inglês, significa lúpulo e trip remete para as diversas viagens que já
            fizemos.
          </p>

          <p>
            Somos de São José dos Campos, no interior de São Paulo, no Brasil mas já vivemos
            um período na Irlanda e foi lá onde todo esse gosto pela cerveja foi adquirido.
            Regressamos ao Brasil e começamos a trabalhar nos festivais cervejeiros com uma
            kombi equipada com 6 torneiras. Mesmo com o retorno, ainda tinhamos uma
            grande atração pela Europa e decidimos vir viver em Portugal com o plano de abrir
            a HopTrip para divulgação da cultura cervejeira artesanal.
          </p>
          <p>
            Nas nossas 15 tap’s, as cervejas artesanais disponíveis estão sempre variando,
            para se proporcionar sabores e experiências diferentes. Uma semana é suficiente
            para que haja mudanças.
          </p>
          <p>
            Para além disso, há ainda dezenas de marcas em garrafas e latas, de Portugal e de
            outros países. E, para acompanhar, temos nossos snacks e a famosa coxinha
            brasileira que é o carro chefe da casa.
          </p>
        </Content>
      </section>
    );
  }
}

export default About;
