import React, { Component } from 'react';

import Social from '../../Atoms/Social';

import './index.scss';

class Footer extends Component {
  render () {
    return (
      <section className="panel">
        <div className="footer">
          <div className="footer-content">
            <Social />

            <p>
              © HopTrip Craft Beer Shop
            </p>
            <p>
              <a href="tel:+351 934672581">+ 351 934 672 581</a>
            </p>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
