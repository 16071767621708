import React, { Component } from 'react';
import GoogleMapReact, { BootstrapURLKeys, Coords, MapOptions } from 'google-map-react';
import { API_KEY, LOCATION, OPTIONS } from './config';

import Logo from '../../../assets/images/logo192.png';

const Marker = (props: any) => {
  const { name } = props;
  const style = {
    cursor: 'pointer',
  };
  return (
    <a href={LOCATION.link} rel="noopener noreferrer" target="_blank" className="marker"
       style={style}
       title={name}>
      <img alt="HopTrip" src={Logo} style={{ height: '25px' }}/>
    </a>
  );
};

type MapProps = {};

type MapStates = {
  bootstrapURLKeys: BootstrapURLKeys,
  defaultCenter: Coords,
  defaultZoom: number,
  options: MapOptions,
};

class Map extends Component<MapProps, MapStates> {
  constructor(props: MapProps) {
    super(props);

    this.state = {
      bootstrapURLKeys: {
        key: API_KEY,
      },
      defaultCenter: {
        lat: LOCATION.lat,
        lng: LOCATION.lng,
      },
      defaultZoom: LOCATION.zoom,
      options: OPTIONS,
    };
  }

  render() {
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: API_KEY }}
          options={OPTIONS}
          defaultCenter={{
            lat: LOCATION.lat,
            lng: LOCATION.lng,
          }}
          defaultZoom={LOCATION.zoom}
        >
          <Marker
            lat={LOCATION.lat}
            lng={LOCATION.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Map;
