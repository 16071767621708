import { combineReducers } from 'redux';
import {
  addEditReducer,
  ComboState,
  deleteReducer,
  AuthState,
  listAllReducer,
  ListAllState,
  authReducer,
} from './combos/reducers';

export interface State {
  comboList: ListAllState;
  comboAddEdit: ComboState;
  comboDelete: ComboState;
  authEmail: AuthState;
}

export const rootReducers = combineReducers<State>({
  comboList: listAllReducer,
  comboAddEdit: addEditReducer,
  comboDelete: deleteReducer,
  authEmail: authReducer,
});
