import * as _ from 'lodash';

import ComboDto from '../../dtos/combo.dto';
import { database } from '../../utils/firebase';

const PATH = '/combos';

export const listAll = async (): Promise<ComboDto[]> => {
  const combos: ComboDto[] = [];

  return new Promise<ComboDto[]>((resolve, reject) => {
    database.ref(PATH)
      .on('value', (snapshot) => {
        if (!snapshot || !snapshot.val()) {
          return resolve([]);
        }

        for (const [key, value] of Object.entries(snapshot.val())) {
          combos.push({
            id: key,
            ...value as ComboDto,
          });
        }
        return resolve(_.sortBy(combos, 'name'));
      }, reject);
  });
};

export const post = (combo: ComboDto): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    database.ref(PATH).push(combo).on('child_added', () => {
      console.log('Success to add a new combo');
      return resolve();
    }, (err: Error) => {
      console.error(err.message);
      return reject();
    });
  });
}

export const put = (combo: ComboDto): Promise<void> => {
  const id = combo.id;

  return new Promise<void>((resolve, reject) => {
    if (!id) {
      console.error(`${combo} does not have ID`);
      return reject();
    }

    delete combo.id;

    database.ref(`${PATH}/${id}`)
      .update(combo)
      .then(resolve)
      .catch(reject);
  });
};

export const del = (combo: ComboDto): Promise<void> => {
  const id = combo.id;

  return new Promise<void>((resolve, reject) => {
    if (!id) {
      console.error(`${combo} does not have ID`);
      return reject();
    }

    database.ref(`${PATH}/${id}`)
      .remove()
      .then(resolve)
      .catch(reject);
  });
};
