import { Route, Redirect } from "react-router-dom";
import React, { ReactNode } from 'react';
import { connect } from "react-redux";
import { State } from '../../../store/reducers';

type Props = {
  path: string;
  isAuthenticated?: boolean;
  children: ReactNode;
}

const PrivateRoute: React.FunctionComponent<Props> = ({ children, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = (state: State) => ({
  isAuthenticated: !!state.authEmail,
});

export default connect(mapStateToProps, null)(PrivateRoute);
