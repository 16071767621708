import React, { Component, Dispatch, FormEvent, MouseEvent, Fragment } from 'react';
import { connect } from 'react-redux';
import { set } from 'lodash';

import { State } from '../../../store/reducers';

import Modal from '../../../components/Atoms/Modal';
import Heading1 from '../../../components/Atoms/Heading/Heading1';
import Heading3 from '../../../components/Atoms/Heading/Heading3';

import ComboDto from '../../../dtos/combo.dto';

import {
  formCancelAction,
  editRequestAction,
  addRequestAction,
  deleteRequestAction, deleteStartAction, deleteCancelAction,
} from '../../../store/combos/actions';

import './index.scss';
import { uploadFile } from '../../../utils/firebase';

type Props = {
  combo: ComboDto,
  edit: (e: MouseEvent<HTMLButtonElement>, combo: ComboDto) => void,
  save: (e: MouseEvent<HTMLButtonElement>, combo: ComboDto) => void,
  delete: (e: MouseEvent<HTMLButtonElement>, combo: ComboDto) => void,
  deleteStart: (e: MouseEvent<HTMLButtonElement>, combo: ComboDto) => void,
  deleteCancel: () => void,
  showDeleteModal: boolean,
  cancel: (e: MouseEvent<HTMLButtonElement>) => void,
};

class ComboView extends Component<Props> {
  onChangeHandler = (event: FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    const combo = this.props.combo;

    set(combo, target.name, target.value);

    this.setState({
      combo,
    });
  };

  onChangeImageHandler = (files: FileList | null) => {
    if (!files || files.length !== 1) return;

    const image = files[0];
    uploadFile('combos/', image)
      .then(url => {

        // add thumb prefix
        const thumbUrl = url.replace('/o/combos%2F', '/o/combos%2Fthumb_');

        const combo = this.props.combo;
        combo.image = url;
        combo.imageThumb = thumbUrl;

        this.setState({ combo });
      })
      .catch(console.error);
  };

  addItem = (e: MouseEvent<HTMLButtonElement>, textKey: number) => {
    e.preventDefault();

    const { combo } = this.props;
    const items = combo.texts[textKey].items;

    if (!items[items.length - 1].text) {
      return;
    }

    // creates an empty line
    items.push({ text: '' });

    this.setState({
      combo,
    });
  };

  deleteItem = (e: MouseEvent<HTMLButtonElement>, textKey: number, itemKey: number) => {
    e.preventDefault();

    const combo = this.props.combo;
    combo.texts[textKey].items.splice(itemKey);

    this.setState({
      combo,
    });
  };

  render() {
    const combo = this.props.combo;

    if (Object.keys(combo).length === 0) {
      return null;
    }

    return (
      <section className="combo-content">
        <Modal onClose={this.props.deleteCancel} show={this.props.showDeleteModal}>
          <p style={{ textAlign: 'center' }}>
            <Heading1 textAlign="center"> Deseja apagar o {combo.name}? </Heading1>
            <br/>
            <div className="col-submit">
              <button type="button" className="btn-submit" onClick={this.props.deleteCancel}>Não</button>
              <button type="button" className="btn-submit btn-submit-danger"
                      onClick={(e) => this.props.delete(e, combo)}>Sim
              </button>
            </div>
          </p>
        </Modal>

        <div className="combo-content-form">

          <form>
            <div className="col-1">
              <label>
                ID
                <input
                  value={combo.id}
                  disabled={true}
                  name="id"
                />
              </label>
            </div>
            <div className="col-3">
              <label>
                Nome *
                <input
                  onChange={this.onChangeHandler}
                  placeholder="Qual o nome do combo?"
                  value={combo.name}
                  name="name"
                />
              </label>
            </div>

            <div className="col-3">
              <label>
                Valor *
                <input
                  type="number"
                  onChange={this.onChangeHandler}
                  placeholder="Qual o valor do combo?"
                  value={combo.amount}
                  name="amount"
                />
              </label>
            </div>

            <div className="col-3">
              <label>
                Imagem *
                <input
                  onChange={(e) => this.onChangeImageHandler(e.target.files)}
                  accept="image/x-png,image/gif,image/jpeg"
                  type="file"
                />
              </label>
            </div>

            <div className="col-1">
              <label>
                Observação
                <input
                  onChange={this.onChangeHandler.bind(this)}
                  placeholder="Ex: Combo disponível apenas nas terças-feiras"
                  name="ps"
                  value={combo.ps}/>
              </label>
            </div>

            <div className="col-section-container">
              {
                combo.texts.map((text, textKey) => {
                  return (
                    <Fragment key={`text-${textKey}`}>
                      <Heading3>{text.title}</Heading3>

                      {
                        text.items.map((item, itemKey) => {
                          return (
                            <div className="col-section-content" key={`item-${itemKey}`}>
                              <div className="col-2">
                                <label>
                                  Nome *
                                  <input
                                    onChange={(e) => this.onChangeHandler(e)}
                                    placeholder="Qual o nome da cerveja?"
                                    value={item.text}
                                    name={`texts[${textKey}].items[${itemKey}].text`}
                                  />
                                </label>
                              </div>

                              <div className="col-2">
                                <label>
                                  Observação
                                  <input
                                    onChange={this.onChangeHandler.bind(this)}
                                    placeholder="Alguma informação adicional?"
                                    value={item.ps}
                                    name={`texts[${textKey}].items[${itemKey}].ps`}
                                  />
                                </label>

                                <div className="col-btn">
                                  {
                                    itemKey !== 0
                                      ? <button
                                        type="button"
                                        className="btn-del"
                                        onClick={(e) => this.deleteItem(e, textKey, itemKey)}>-</button>
                                      : null
                                  }

                                  {
                                    itemKey === text.items.length - 1
                                      ? <button
                                        type="button"
                                        className="btn-add"
                                        onClick={(e) => this.addItem(e, textKey)}>+</button>
                                      : null
                                  }
                                </div>
                              </div>
                            </div>
                          );
                        })
                      }
                    </Fragment>
                  );
                })
              }
            </div>

            <div className="col-submit">
              {
                combo.id
                  ? <>
                    <button type="button" className="btn-submit" onClick={this.props.cancel}>
                      Cancelar
                    </button>
                    <button type="button" className="btn-submit" onClick={(e) => this.props.edit(e, combo)}>
                      Atualizar
                    </button>
                  </>
                  : <>
                    <button type="button" className="btn-submit" onClick={this.props.cancel}>
                      Cancelar
                    </button>
                    <button type="button" className="btn-submit" onClick={(e) => this.props.save(e, combo)}>
                      Salvar
                    </button>
                  </>
              }
            </div>
            <button
              type="button"
              className="btn-submit btn-submit-danger"
              onClick={(e) => this.props.deleteStart(e, combo)}>
              Apagar
            </button>
          </form>

        </div>

        <div className="combo-content-image">
          <img src={combo.image} alt={combo.imageThumb}/>
          <Heading3 textAlign="center">{combo.name}</Heading3>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state: State) => ({
  combo: state.comboAddEdit,
  showDeleteModal: Object.keys(state.comboDelete).length > 0,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  save: (e: MouseEvent<HTMLButtonElement>, combo: ComboDto) => {
    e.preventDefault();
    dispatch(addRequestAction(combo));
  },
  edit: (e: MouseEvent<HTMLButtonElement>, combo: ComboDto) => {
    e.preventDefault();
    dispatch(editRequestAction(combo));
  },
  deleteStart: (e: MouseEvent<HTMLButtonElement>, combo: ComboDto) => {
    e.preventDefault();
    dispatch(deleteStartAction(combo));
  },
  deleteCancel: () => {
    dispatch(deleteCancelAction());
  },
  delete: (e: MouseEvent<HTMLButtonElement>, combo: ComboDto) => {
    e.preventDefault();
    dispatch(deleteRequestAction(combo));
  },
  cancel: (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(formCancelAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ComboView);
