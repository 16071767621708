import React, { Component } from 'react';

import Instagram from '../../../assets/images/social/instagram.png';
import Whatsapp from '../../../assets/images/social/whatsapp.png';
import Messenger from '../../../assets/images/social/messenger.svg';
import Facebook from '../../../assets/images/social/facebook.png';

import './index.scss';

class Social extends Component {
  render() {
    return (
      <section className="social">
        <a href='https://www.instagram.com/hoptripcraftbeer' className="social-item" rel="noopener noreferrer" target="_blank">
          <img alt="Instagram" src={ Instagram }/>
        </a>
        <a href='https://wa.me/351934672581' className="social-item" rel="noopener noreferrer" target="_blank">
          <img alt="Whatsapp"  src={ Whatsapp }/>
        </a>
        <a href='https://www.messenger.com/t/hoptripcraftbeer' className="social-item" rel="noopener noreferrer" target="_blank">
          <img alt="Messenger"  src={ Messenger }/>
        </a>
        <a href='https://www.facebook.com/hoptripcraftbeer' className="social-item" rel="noopener noreferrer" target="_blank">
          <img alt="Facebook"  src={ Facebook }/>
        </a>
      </section>
    );
  }
}

export default Social;
