import React, { Component } from 'react';

import Heading2 from '../../Atoms/Heading/Heading2';

import './index.scss';

type HeaderProps = {
  text: string;
}

class Header extends Component<HeaderProps, {}> {
  render() {
    const { text } = this.props;

    return (
      <section className="header">
        <Heading2 textAlign="center" ico={true}>{text}</Heading2>
      </section>
    );
  }
}

export default Header;
