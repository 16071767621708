import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import Admin from './pages/Admin';
import Login from './pages/Admin/Login';
import PrivateRoute from './pages/Admin/PrivateRoute';

function App() {
  return (
    <main>
      <div className="container">
        <Switch>
          <PrivateRoute path="/admin">
            <Admin />
          </PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </main>
  );
}

export default App;
