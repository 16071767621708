import React, { Component } from 'react';

import './index.scss';

type ModalProps = {
  show: boolean,
  onClose: Function,
}

class Modal extends Component<ModalProps, {}> {
  constructor(props: ModalProps) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  static defaultProps = {
    show: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (event && event.target.getAttribute('class') === 'modal') {
      this.props.onClose(event);
    }
  }

  render() {
    const {
      show,
      children
    } = this.props;

    if (!show) return null;

    return (
      <section className="modal">
        <div className="modal-content">
          { children }
        </div>
      </section>
    );
  }
}

export default Modal;
