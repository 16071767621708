import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import * as api from './api';
import * as action from './actions';
import { State } from '../reducers';
import { getUserAuthEmail } from '../../utils/firebase';

// list
function* requestAllCombos() {
  try {
    const combos = (yield call(api.listAll));
    yield put(action.listAllCompletedAction(combos));
  } catch (e) {
    console.error(e);
    yield put(action.listAllFailedAction(e.message));
  }
}

function* watchListAllStart(): Generator {
  yield takeEvery(
    action.ACTION_ID.LIST_ALL_REQUEST_START,
    requestAllCombos,
  );
}

// put
function* performPut() {
  try {
    const combo = yield select((state: State) => state.comboAddEdit);

    yield call(api.put, combo);
    yield put(action.editCompleteAction());
    yield put(action.formCancelAction());
    yield call(requestAllCombos);

  } catch (e) {
    console.error(e);
    yield put(action.editSaveFailedAction(e.message));
  }
}

function* watchUpdateRequest() {
  yield takeEvery(
    action.ACTION_ID.EDIT_REQUEST,
    performPut,
  );
}

// post
function* performPost() {
  try {
    const combo = yield select((state: State) => state.comboAddEdit);

    yield call(api.post, combo);
    yield put(action.addCompleteAction());
    yield put(action.formCancelAction());
    yield call(requestAllCombos);

  } catch (e) {
    console.error(e);
    yield put(action.addFailedAction(e.message));
  }
}

function* watchSaveRequest() {
  yield takeEvery(
    action.ACTION_ID.ADD_REQUEST,
    performPost,
  );
}

// post
function* performDelete() {
  try {
    const combo = yield select((state: State) => state.comboAddEdit);

    yield call(api.del, combo);
    yield put(action.deleteCompleteAction());
    yield put(action.formCancelAction());
    yield call(requestAllCombos);

  } catch (e) {
    console.error(e);
    yield put(action.deleteFailedAction(e.message));
  }
}

function* watchDeleteRequest() {
  yield takeEvery(
    action.ACTION_ID.DELETE_REQUEST,
    performDelete,
  );
}

// user auth
function* authCheck() {
  const userEmail = yield call(getUserAuthEmail);
  yield put(action.userLogin(userEmail))
}

function* watchUserAuth() {
  yield takeEvery(
    action.ACTION_ID.USER_AUTH_CHECK,
    authCheck,
  );
}

export default function* combosSagas() {
  yield all([
    fork(watchListAllStart),
    fork(watchSaveRequest),
    fork(watchUpdateRequest),
    fork(watchDeleteRequest),
    fork(watchUserAuth),
  ]);
}
