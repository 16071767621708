import React, { Component } from 'react';

import '../index.scss';
import { TextAlignProperty } from 'csstype';

type Heading3Props = {
  textAlign?: TextAlignProperty,
};

class Heading3 extends Component<Heading3Props, {}> {
  public static defaultProps = {
    ico: false,
    textAlign: 'left',
  };

  render() {
    const { textAlign, children } = this.props;

    return (
      <h3 style={{ textAlign }}>{children}</h3>
    );
  }
}

export default Heading3;
